import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/messaging";
import "firebase/functions";

console.log("firebaseはいけてるはず！")

export default firebase.initializeApp({
  apiKey: "AIzaSyDdf3CyXEF1b6DU9dmPNrQE6YiOg3UkrsM",
  authDomain: "relis-firebase.firebaseapp.com",
  databaseURL: "https://relis-firebase.firebaseio.com",
  projectId: "relis-firebase",
  storageBucket: "relis-firebase.appspot.com",
  messagingSenderId: "295218839510"
});