import React from 'react';
import firebase from '../firebase';
import ReactDOM from 'react-dom';
//import { Calendar } from 'react-native-calendars';
import Calendar from 'react-calendar';
//import Calendar from 'react-calendar/dist/entry.nostyle';
//import BigCalendar from 'react-big-calendar';
import FullCalendar from 'fullcalendar-reactwrapper';
import 'fullcalendar-reactwrapper/dist/css/fullcalendar.min.css';
import Hoge from './hoge';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    events:[
                {
                    title: 'All Day Event',
                    start: '2019-05-01'
                },
                {
                    title: 'Long Event',
                    start: '2019-05-07',
                    end: '2019-05-10'
                },
                {
                    id: 999,
                    title: 'Repeating Event',
                    start: '2019-05-09T16:00:00'
                },
                {
                    id: 999,
                    title: 'Repeating Event',
                    start: '2019-05-16T16:00:00'
                },
                {
                    title: 'Conference',
                    start: '2019-05-11',
                    end: '2019-05-13'
                },
                {
                    title: 'Meeting',
                    start: '2019-05-12T10:30:00',
                    end: '2019-05-12T12:30:00'
                },
                {
                  title: 'Meeting2',
                  start: '2019-05-12T10:30:00',
                  end: '2019-05-12T12:30:00'
                },
                {
                    title: 'Birthday Party',
                    start: '2019-05-13T07:00:00'
                },
                {
                    title: 'Click for Google',
                    url: 'http://google.com/',
                    start: '2019-05-28'
                }
            ],		
    }
  }




    render() {
      
      return (
        <div  >


          <FullCalendar
            id = "your-custom-ID"
            header = {{
               left: 'prev,next today myCustomButton',
               center: 'title',
               right: 'month,basicWeek,basicDay'
           }}
           
           navLinks= {true} // can click day/week names to navigate views
           editable= {true}
           eventLimit= {true} // allow "more" link when too many events
           events = {this.state.events}
           viewDisplay = {function(view) {
            alert('ビュー表示イベント ' + view.title);
            
            }} 
            eventClick = {function(){
              alert('buhooooooooooooo');
            }}
          />
      
           
          <button onClick={ () => firebase.auth().signOut() }>sign out</button>
        </div>
      );
    }
  };


export default Home;