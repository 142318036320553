import React from 'react';
import { BrowserRouter, Route, Redirect } from "react-router-dom";
import Login from './components/Login';
import Home from './components/Home';
import Hoge from './components/hoge';

import firebase from './firebase';

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = { loading: true, authenticated: false, user: null };
  }

  componentWillMount() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.setState({
          authenticated: true,
          currentUser: user,
          loading: false
        });
        console.log("loginの結果：", this.state)
      } else {
        this.setState({
          authenticated: false,
          currentUser: null,
          loading: false
        });
        console.log("loginの結果：", this.state)
      }
    });
  }

  render() {
    const { authenticated, loading } = this.state;
    console.log("loginの結果(render関数内)：", this.state)
    console.log(authenticated)
    console.log(window.location.href)
    const buhi = window.location.href
    if (loading) return <p>loading..</p>;
    return (
      <BrowserRouter>
        <div>
          <Route
              exact
              path="/"
              render={() =>
                authenticated === true ? (
                  <Home /> 
                ) : (
                  <Redirect to="/login" />
                )
              }A
            />
            <Route
              exact
              path="/login"
              render={() =>
                authenticated === false ? (
                  <Login />
                ) : (
                  <Redirect to="/" />
                )
              }
            />

        <Hoge/>
        <p>hooooooooooooooooooooooooooooooo</p>  
        </div>
      </BrowserRouter>
    );
  }
};


export default App;