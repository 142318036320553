import React from 'react';

//import firebase from '../firebase';

class hoge extends React.Component {
    constructor(props) {
      super(props);
    }
    componentDidMount(){
        return fetch('https://myprest.prest-square.jp/php/index.php')
          .then((response) => response.json())
          .then((responseJson) => {
 
            this.setState({
              isLoading: false,
              dataSource: responseJson,
            }, function(){
                
                
                
                
                
                console.log(responseJson)
            });
 
          })
          .catch((error) =>{
            console.error(error);
          });
    }
  
  
  
      render() {
        console.log(this.state)
        return (
          <div  >
        

            <button onClick={ () => console.log("まぁこれくらいは動くよね") }>タップしてみてー</button>
           
          </div>
        );
      }
    };



export default hoge;